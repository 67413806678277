<template>
  <div>
    <teamList></teamList>
  </div>
</template>

<script>
import teamList from '@/components/team/join.vue'
export default {
  name: 'Team',
  components: {
    teamList
  }

}
</script>

<style>

</style>
